
import Image from "next/image";
import Link from "next/link";

import separator from "../../public/images/separator/separator-top.svg";

const Home = () => {

  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h1 className="title display-one">
                  Earn More Do Less <br />
                  with{" "}<span className="theme-gradient">ETIPort.AI</span>
                  {/* <span className="color-off">Smart Algorithms</span> */}
                </h1>
                <p className="b1 desc-text">
                  Get advanced backtesting, real-time AI-driven buy/sell alerts, and seamless cryptocurrency trading automation with our platform.
                </p>
                <div className="button-group">
                  <Link
                    className="btn-default bg-light-gradient btn-large"
                    href="/earlyregister">
                    <div className="has-bg-light"></div>
                    <span>Early register now</span>
                  </Link>
                </div>
                <p className="color-gray mt--5">💳 No credit card required!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator has-position-bottom">
          <Image className="w-100" src={separator} alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
