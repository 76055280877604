import Image from "next/image";

import tron from "../../public/images/brand/tron-logo.svg";
import litecoin from "../../public/images/brand/litecoin-logo.svg";
import bitcoin from "../../public/images/brand/bitcoin-logo.svg";
import ethereum from "../../public/images/brand/ethereum-logo.svg";
import tether from "../../public/images/brand/tether-usdt-logo.svg"
import binance_bnb from "../../public/images/brand/binance-bnb-logo.svg"
import dogecoin from "../../public/images/brand/dogecoin-doge-logo.svg"
import cordano from "../../public/images/brand/cordano-logo.svg"
import solana from "../../public/images/brand/solana-logo.svg"
import usdcoin from "../../public/images/brand/usd-coin-logo.svg"
import shiba from "../../public/images/brand/shiba-logo.svg"

const Brands = () => {
  return (
    <>
      <div className="rainbow-brand-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center sal-animate"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">350+ CRYPTOCURRENCIES FOR YOUR TRADE</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt--10">
              <ul className="brand-list brand-style-2">
                <li>
                  <a href="#">
                    <Image
                      src={bitcoin}
                      width={148}
                      height={70}
                      alt="Bitcoin"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={ethereum}
                      width={148}
                      height={70}
                      alt="Ethereum"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={tether}
                      width={148}
                      height={70}
                      alt="Tether USDT"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={binance_bnb}
                      width={148}
                      height={70}
                      alt="Binance BNB"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={dogecoin}
                      width={148}
                      height={70}
                      alt="Degocoin"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={cordano}
                      width={148}
                      height={70}
                      alt="Cordano"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={solana}
                      width={148}
                      height={70}
                      alt="Solana"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={tron}
                      width={148}
                      height={70}
                      alt="Binance"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={litecoin}
                      width={148}
                      height={70}
                      alt="Litecoin"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={usdcoin}
                      width={148}
                      height={70}
                      alt="USD Coin"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Image
                      src={shiba}
                      width={148}
                      height={70}
                      alt="Shiba Inu"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
