import React, { useEffect, useState } from "react";
import sal from "sal.js";

import PricingData from "../../data/home.json";
import Link from "next/link";
import PriceLabel from "./PriceLabel";


const Pricing = () => {
  const [monthlySubscription, setMonthlySubscription] = useState(false);

  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);

  return (
    <>
      <div className="rainbow-pricing-area rainbow-section-gap" id="sec_pricing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">PRICING</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  Try Risk-free
                </h2>
                <h3 className="title w-500">
                  Choose one-time-use or subscription based package
                </h3>
                {/* <p className="description b1">
                  Select a one-time use package or an advanced subscription package
                </p> */}
              </div>
            </div>
          </div>
          <div className="row row--15">
            {/* <nav className="chatenai-tab">
              <div
                className="tab-btn-grp nav nav-tabs mb-3 text-center justify-content-center"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link with-badge"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="false"
                  onClick={() => setMonthlySubscription(true)}
                >
                  Monthly
                </button>
                <button
                  className="nav-link active"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="true"
                  onClick={() => setMonthlySubscription(false)}
                >
                  Annual
                  <span className="rainbow-badge-card badge-border">
                    20% Off
                  </span>
                </button>
              </div>
            </nav> */}
            {PricingData &&
              PricingData.pricing.map((data, index) => (
                <div className="col-lg-3 col-md-6 col-12" key={index}>
                  <div
                    className={`rainbow-pricing style-2 ${data.isActive ? "active" : ""
                      }`}
                  >
                    <div className="pricing-table-inner bg-flashlight">
                      {data.id === 2 ? (
                        <span className="rainbow-badge-card">Most Popular</span>
                      ) : (
                        ""
                      )}
                      <div className="pricing-header">
                        <h4 className="title">{data.title}</h4>
                        <div className="pricing">
                          <div className="price-wrapper">
                            {data.price === 0 ? (
                              ""
                            ) : (
                              <span className="currency">$</span>
                            )}
                            <span className="price">
                              {/* {data.price === 0 ? "Free" : (monthlySubscription ? data.price * 1.2 : data.price)} */}
                              {data.price === 0 ? "Free" : <PriceLabel price={data.price} /> }
                            </span>
                          </div>
                          <span className="subtitle">{data.subTitle}</span>
                          <span className="subtitle">{data.text}</span>
                        </div>
                      </div>
                      <div className="separator-animated animated-true mt--30 mb--30"></div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          {data.subItem.map((innerData, innerIndex) => (
                            <li key={innerIndex}>
                              <i className={`feather-${innerData.isMinus ? "check" : "check-circle"
                                  } ${innerData.isMinus ? "color-gray" : "color-success"} `}></i>
                              &nbsp;<span className="subtitle">{innerData.text}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        {data.price === 0 ? (
                          <Link className="btn-default btn-border" href="#">
                            Try it now
                          </Link>
                        ) : (
                          <Link
                            className={`btn-default ${!data.isOneOff ? "bg-solid-primary" : ""
                              }`}
                            href={`/earlyregister?subId=${data.id}&isMonthly=${monthlySubscription}`}
                          >
                            {data.isOneOff ? "Purchase Now" : "Subscribe Now"}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
