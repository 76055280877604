import React from 'react';

const PriceLabel = ({ price }) => {
    const formatPrice = (price) => {
        const [integerPart, decimalPart] = (typeof price === 'number' ? price.toFixed(2) : price).split('.');
        return { integerPart, decimalPart };
    };
    const { integerPart, decimalPart } = formatPrice(price);

    return (
        <div>
            {integerPart}
            {decimalPart && <sup>.{decimalPart}</sup>}
        </div>
    );
};

export default PriceLabel;
